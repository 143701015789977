import React from "react";
import { graphql } from "gatsby";
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import AppConfig from '../appconfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl} from 'react-intl';

class ActivateContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            successFeedback: ''
        };

        this.checkActivationToken = this.checkActivationToken.bind(this);
    }

    componentDidMount() {
        if (!this.props.loggedIn) {
            let token = this.getTokenFromQueryString();
            if (!token) {
                this.setState({
                    successFeedback: this.props.intl.formatMessage({ id: 'weSentYouMail' })
                });
            } else {
                this.checkActivationToken(token);
            }
        }
    }

    getTokenFromQueryString() {
        var query = this.props.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] === "token") {
                return pair[1];
            }
        }
        return false;
    }

    checkActivationToken(token) {
        let url = AppConfig.API_URL + 'checkActivationToken';

        url += ('/' + token);

        fetch(url, {
            credentials: 'include'
        })
        .then(res => res.json())
        .then(
            result => {
                if (result.success) {
                    this.setState({
                        successFeedback: 'Verifieringen av ditt konto lyckades! Du kan nu logga in och börja diskutera direkt!'
                    });
                } else {
                    this.setState({
                        successFeedback: this.props.intl.formatMessage({ id: 'couldNotActivate' })
                    });
                }
            },
            error => {
                this.setState({
                    successFeedback: this.props.intl.formatMessage({ id: 'couldNotActivate' })
                });
            }
        );

        return false;
    }


    render() {

        if (this.props.loggedIn) {
            return (
                <div className="chatPageWrapper page">
                    <div className="errorText">
                        <FormattedMessage id="alreadyLoggedIn" />
                        <br /><FontAwesomeIcon icon={faCheckCircle} />
                    </div>
                </div>
            );

        } else if (this.state.successFeedback != undefined && this.state.successFeedback != ''){
            return (
                <div className="chatPageWrapper page">
                    <div className="errorText">
                        {this.state.successFeedback}
                         <br /><FontAwesomeIcon icon={faCheckCircle} />
                    </div>
                </div>
            );
        } else {
            return(
                <div className="chatPageWrapper page">
                    <div className="dcLoader medium preLoader" />
                </div>
            );
        }
        
    }
}

function mapStateToProps(state) {
    const { loggedIn } = state
    return { loggedIn: loggedIn }
}


export default injectIntl(connect(
    mapStateToProps
)(ActivateContent));